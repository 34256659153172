import { GraphQLClient } from 'graphql-request';

import { GRAPHQL_API_URI } from '../../../../config';

import { UUID } from '../../../../types';

import { generateNanoId } from '../../../../utils/generateNanoId';

interface DeleteInviteUserSearchProps {
  query: string;
  input: DeleteInviteUserSearchInput;
}

const graphQLClient = new GraphQLClient(GRAPHQL_API_URI, {
  credentials: 'include',
  mode: 'cors'
});

export interface DeleteInviteUserSearchInput {
  clientMutationId?: string;
  uuid: UUID;
}

export interface DeleteInviteUserSearchError {
  fullMessages: string[] | null;
}

export interface DeleteInviteUserSearchResponse {
  deleteInviteUserSearch: {
    status: string;
    errors: DeleteInviteUserSearchError;
  };
}

export function deleteInviteUserSearch({
  query,
  input
}: DeleteInviteUserSearchProps) {
  const clientMutationId = generateNanoId();
  return new Promise<DeleteInviteUserSearchResponse>((resolve, reject) => {
    return graphQLClient
      .request<DeleteInviteUserSearchResponse, DeleteInviteUserSearchInput>(
        query,
        { clientMutationId, ...input }
      )
      .then((data) => {
        if (data.deleteInviteUserSearch.status === 'deleted') {
          return resolve(data);
        }

        reject(data.deleteInviteUserSearch.errors);
      })
      .catch((reason) => reject(reason));
  });
}
