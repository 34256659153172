import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { FetchTodoItemsSortTypes } from '../../../../../../todoItems/todoItemsTypes';
import { MessagesListMessageTodoListMessage } from './MessagesListMessageTodoList.types';

import {
  FETCH_TODO_LIST_TODO_ITEMS,
  FetchTodoListTodoItemsQueryResponse
} from '../../../../../../todoItems/queries/fetchTodoListTodoItems.query';

import { usePaginatedTodoItems } from '../../../../../../todoItems/hooks/usePaginatedTodoItems';

import { ItemMessagesListMessageTodoListItem } from '../../../ItemMessagesListMessage/components/ItemMessagesListMessageTodoList/components/ItemMessagesListMessageTodoListItem';

import { TodoItemsCache } from '../../../../../../todoItems/TodoItemsCache';
import { TODO_ITEM_NAME_TITLE } from '../../../../../../todoItems/todoItemsConstants';
import { filter, find } from 'lodash';
import { sortTodoItemsByOrder } from '../../../../../../todoItems/utils/sortTodoItemsByOrder';

const regexTodoItemNameAsTitle = new RegExp(`^\\${TODO_ITEM_NAME_TITLE}`);

interface MessagesListMessageTodoListProps {
  message: MessagesListMessageTodoListMessage;
}

function MessagesListMessageTodoList({
  message
}: MessagesListMessageTodoListProps) {
  const cacheKey = TodoItemsCache.messageItemsCacheKey(message.uuid);

  const { todoItems, updateTodoItemCache } =
    usePaginatedTodoItems<FetchTodoListTodoItemsQueryResponse>({
      cacheKey,
      query: FETCH_TODO_LIST_TODO_ITEMS,
      initialSort: [FetchTodoItemsSortTypes.ID_ASC],
      initialFilters: { messageUuid: message.uuid },
      initialLimit: 1000,
      options: {
        withoutPrefetch: true
      }
    });

  const todoListItems = useMemo(
    () =>
      filter(
        sortTodoItemsByOrder(
          isEmpty(todoItems) ? message.todoItems : todoItems
        ),
        (item) => !regexTodoItemNameAsTitle.test(item.name)
      ),
    [message.todoItems, todoItems]
  );

  const todoTitle = useMemo(() => {
    const titleTodoItem = find(
      isEmpty(todoItems) ? message.todoItems : todoItems,
      (item) => regexTodoItemNameAsTitle.test(item.name)
    );

    return titleTodoItem?.name.replace(regexTodoItemNameAsTitle, '');
  }, [message.todoItems, todoItems]);

  return (
    <div>
      <div className="break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug">
        <div className="px-1">
          {todoTitle && (
            <div className="mb-2">
              <div className="font-semibold px-8 py-1 text-base">
                {todoTitle}
              </div>
            </div>
          )}

          {todoListItems.map((todoItem) => (
            <ItemMessagesListMessageTodoListItem
              key={todoItem.id}
              cacheKeys={[cacheKey]}
              todoItem={todoItem}
              updateTodoItemCache={updateTodoItemCache}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MessagesListMessageTodoList;
