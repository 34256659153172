import { MatchResultUUID } from './matchResultsTypes';

export class MatchResultBffUrl {
  static matchingSingleResults(matchResultUuid: MatchResultUUID) {
    return `modeling/${matchResultUuid}/matching_single_results`;
  }

  static matchSingleObjectByDetectedBounding() {
    return `modeling/match_single_object_by_detected_bounding`;
  }

  static matchSingleObjectByAngle() {
    return `modeling/match_single_object_by_angle`;
  }

  static textSearchRequest() {
    return `texting/text_search_request`;
  }

  static clipSearchRequest() {
    return `texting/clip_search_request`;
  }

  static clipGlobalSearchRequest() {
    return `texting/clip_global_search_request`;
  }
}
